:root {
  --default-background-color: #EFF8FF;
  --default-text-color: #434B9F;
  --secondary-text-color-light: #5D6892;
  --default-input-bg: #FAFAFF;
  --default-input-shadow: #b5bbbf;
  --forgot-password-text-color: #6B75E0;
  --white: #FFFFFF;
  --secondary-text-color: #1C0850;
  --default-btn-color: #535DCA;
  --dashboard-bg-color: #6A5CF7;
  --btn-text-color: #806EC0;
}

.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 200px !important;
}

.custom-placeholder::placeholder {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  color: var(--secondary-text-color); 
}

.custom-placeholder:-ms-input-placeholder {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  color: var(--secondary-text-color);
}

.custom-placeholder::-ms-input-placeholder {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  color: var(--secondary-text-color);
}