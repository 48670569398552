.forgetPasswordmodalclose {
    position: absolute;
    top: 0;
    right: 0;
}

.ModelContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* width: 90%; */
    margin: auto;
    row-gap: 10px;
}

.successModalHeading {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    text-align: center;
    color: var(--side-nav-background-color);
}

.successModalSubHeading {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: center;
    color: var(-login-sub-header-text-color);
}

.logoutBtnContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.logoutBtnContainer button {
    width: 127px;
    height: 40px;
    border-radius: 75px;
    border: 1px solid var(--forgot-password-text-color);
    text-transform: capitalize !important;
}