.row_one{
    display: flex;
    justify-content: space-between;
    background-color: #FFF1EB !important;
    padding: 15px 100px;
}
.row_one_left{
    display: flex;
    align-items: center;
    gap: 10px;
}
.row_one_left img{
    width: 95%;
    height: 91%;
    background-color: #83E0F0 !important;
}
.row_one_left p{
    color: var(--secondary-text-color) !important;
    font-size: 32px !important;
    font-weight: 700 !important;
}
.row_one_left span{
    color: var(----secondary-text-color-light) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
}
.row_one_rigth{
    background-color: #fff !important;
    width: 282px !important;
    border-radius: 12px;
    padding: 10px;
}
.row_one_rigth p{
    color: var(--secondary-text-color) !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}
.row_two{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    position: relative;
}
.swipper_container{
    width: 80%;
    z-index: 2;
}
.card_open{
    width:90% !important;
    margin: auto 5%;
    height: 270px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    text-align: center;
    z-index: -1;
    border-radius: 10px 10px 30px 30px;
    cursor: pointer;
}
.card_open_active{
    background-color: #e3d4ff;
    box-shadow: 0 0 15px #e3d4ff;
}
/* .card_open img{
    margin-top: -30px;
    pointer-events: none;
} */
.card_open svg{
    cursor: pointer;
}
.mylevel{
    color: var(--secondary-text-color-light);
    font-size: 32px !important;
    font-weight: 700 !important;
    margin-top: 16px;
    text-transform: capitalize;
}
.reward{
    width: 80%;
    background: #F9FAFF ;
    margin: 14px auto 1px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 18px;
    padding: 0 6px;
}
.reward svg{
    width: 24px;
}
.reward p{
    color: var(--secondary-text-color-light) ;
    font-size: 24px !important;
    font-weight: 600 !important;
}
.reward span{
    color: var(--secondary-text-color-light) ;
    font-size: 32px !important;
    font-weight: 700 !important;
}
.card_active{
    color: var(--secondary-text-color);
}
.reward_active{
    background-color: #fff !important;
}
.reward_active p{
    color: var(--secondary-text-color) ;
}
.reward_active span{
    color: var(--secondary-text-color) ;
}
.btm_icons{
    margin-top: 40px !important;
}
.lock_icon svg{
    margin-top: 15px !important;
}
.cloud_img{
    position: absolute;
    top: 0;
    left: 50px;
    z-index: 1;
    user-select: none;
}
.ballon_img{
    position: absolute;
    top: 15px;
    right: 40px;
    z-index: 1;
    user-select: none;
}
.star_img{
    position: absolute;
    bottom: -20px;
    right: 0px;
    z-index: 1;
    user-select: none;
}
.middle_img{
    margin-top: -55px;
}
.avatar_url{
    border-radius: 50%;
    background-color: #e0e9f2;
    cursor: pointer;
}
.split_letters_two{
    width: 45px;
    border-radius: 50%;
    text-align: center;
    font-size: 25px !important;
    font-weight: 600 !important;
    background-color:#e0e9f2;
    cursor: pointer;
    border: 5px solid #854dff;
    display: inline-block;
    color: #854dff !important;
}

@media screen and (max-width: 850px) {
.row_one{
    padding: 15px 20px;
    display: block;
}
.row_one_rigth{
    width: 100% !important;
    margin-top: 10px;
}
}