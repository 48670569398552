.LoginMainCard {
    min-width: 490px;
    padding: 30px;
    border-radius: 24px !important;
}

.authHeader {
    font-size: 40px !important;
    line-height: normal !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: var(--default-text-color);
}

.authHeaderSubText {
    font-size: 16px !important;
    line-height: normal !important;
    font-weight: 400 !important;
    text-align: center !important;
    color: var(--secondary-text-color);
}

.formInputContainer {
    /* display: flex !important;
    flex-direction: column;
    row-gap: 20px !important;
    align-items: center;
    width: 100%; */
}

.TextInput>div {
    border-radius: 12px !important;
    height: 56px !important;
    background-color: var(--default-input-bg) !important;
    border-color: var(--default-input-bg) !important;
    filter: drop-shadow(0px 4px 4px var(--default-input-shadow)) !important;
}

.TextInput1>div {
    border-radius: 12px !important;
    height: 56px !important;
    background-color: var(--default-input-bg) !important;
    border-color: var(--default-input-bg) !important;
    filter: drop-shadow(0px 4px 4px var(--default-input-shadow)) !important;
}

.otpInputContainer {
    column-gap: 8px !important;
}

.otpInput {
    border-radius: 12px !important;
    height: 56px !important;
    background-color: var(--default-input-bg) !important;
    border: none !important;
    filter: drop-shadow(0px 4px 4px var(--default-input-shadow)) !important;
    height: 53px !important;
    width: 90px !important;
}

.TextInput fieldset,
.TextInput>div:hover,
.TextInput1 fieldset,
.TextInput1>div:hover {
    border-color: var(--default-input-bg) !important;
}

.otpInput input {
    text-align: center !important;
}

.otpWrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.TextInput input {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: var(--secondary-text-color);
    opacity: 1 !important;
}

.TextInput input::placeholder,
.TextInput1 input::placeholder {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: var(--secondary-text-color);
    opacity: 1 !important;
}

.menuPaper {}

.passwordInputAdornment {
    margin-right: 10px !important;
}

.loginSubmitBtnContainer {
    display: flex;
    justify-content: center
}

.loginSubmitBtn {
    height: 56px !important;
    min-width: 240px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--white);
    background: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
}

.forgotPasswordText {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 35px !important;
    text-align: right !important;
    width: 100% !important;
    color: var(--forgot-password-text-color);
    cursor: pointer !important;
}

.socialLoginBtnContainer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
}

.faceBookBtn,
.googleBtn {
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.LoginMainDiv {
    position: relative;
}

.footerBtn {
    position: absolute !important;
    height: 56px !important;
    min-width: 270px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    letter-spacing: 1px !important;
    text-align: left !important;
    text-transform: none !important;
    background: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -11;
}

.selectAge {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: var(--secondary-text-color);
    opacity: 1 !important;
    margin: 0 !important;
}

.chooseavatar {
    background: linear-gradient(90deg, var(--default-background-color), transparent);
    height: 100vh;
    position: relative;
}

.imgone {
    position: absolute;
    bottom: 0;
    left: 0;
}

.imgtwo {
    position: absolute;
    bottom: 0;
    right: 0;
}

.imgthree {
    position: absolute;
    top: 0;
    right: 0;
}

.imgfour {
    position: absolute;
    top: 60px;
    right: 91px;
}

.imgfive {
    position: absolute;
    top: 119px;
    left: 103px;
}

.imgsix {
    position: absolute;
    top: 40%;
    left: 91px;
}

.imgseven {
    position: absolute;
    top: 110px;
    right: 91px;
}

.changeavatar {
    font-size: 20px !important;
    line-height: normal !important;
    font-weight: 400 !important;
    color: var(--default-text-color);
    position: absolute;
    top: 80px;
    right: 91px;
    cursor: pointer;
}

.skipavatar {
    font-size: 20px !important;
    line-height: normal !important;
    font-weight: 500 !important;
    color: var(--default-text-color);
    position: absolute;
    top: 125px;
    right: 130px;
    cursor: pointer;
}

.rightarrow {
    position: absolute;
    right: 0px;
    top: 39%;
}

.leftarrow {
    position: absolute;
    left: 0px;
    top: 39%;
}

.rightarrowfriend {
    position: absolute;
    right: 48px;
    top: 41%;
}

.leftarrowfriend {
    position: absolute;
    left: 54px;
    top: 41%;
}

.metaverselogo {
    height: 40px !important;
    position: absolute;
    top: 15px;
    left: 150px;
}

.welcomemsg {
    font-size: 24px !important;
    line-height: normal !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: var(--secondary-text-color) !important;
    margin-bottom: 14px;
}

.SliderImage {
    width: 60% !important;
    margin: 5% 10% !important;
    height: auto;
}

.activeImage {
    border: 5px solid var(--forgot-password-text-color);
    border-radius: 50%;
    transform: scale(1.3) !important;
    background-color: #e1eaf3 !important;
    /* width:  !important; */
}
.inactiveImage{
    filter: grayscale(1);
    border-radius: 50%;
    background-color: #e1eaf3 !important;
}
.activeFriend{
    transform: scale(1.3) !important;
}

.SliderContainer {
    padding: 5% 0 !important;
}
.edit_icon{
    cursor: pointer !important;
    display: inline-block;
}
.new_countrycode_field > div{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.01);
    border-radius: 9px;
    border-radius: 12px !important;
    height: 56px !important;
    background-color: var(--default-input-bg) !important;
    border-color: var(--default-input-bg) !important;
    filter: drop-shadow(0px 4px 4px var(--default-input-shadow)) !important;
}

.new_countrycode_field input{
    border: none !important;
    height: 100% !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: var(--secondary-text-color) !important;
}

.new_countrycode_field input:focus {
    /* color: var(--bs-body-color);
    background-color: var(--bs-body-bg); */
    border-color: #1976d2 !important;
    border-width: 2px !important;
    /* outline: 0; */
    box-shadow: none !important; 
}
 .new_countrycode_field ul{
    width: 100% !important;
}
.err_text{
    color: #d32f2f !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

@media screen and (max-width: 850px) {
    .LoginMainCard {
        min-width: 80vw;
        margin: 0 10vw;
    }
}