.DefaultLayoutMaindiv {
  background: linear-gradient(
    90deg,
    var(--default-background-color),
    transparent
  );
  height: 100vh;
  position: relative;
}

.AuthDefaultImage {
  width: 35vw;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translate(40%, -35%);
}

.Authlogo {
  height: 40px !important;
  position: absolute;
  left: 10%;
  top: 10px;
}

.guestCloudIcon {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.guestCodeIcon {
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translate(0, -50%);
}

.guestRocketIcon {
  position: absolute;
  right: 0;
  bottom: 0;
}

.gueststraightButterflyIcon {
  position: absolute;
  left: 10%;
  top: 20%;
  /* transform: translate(0, -50%); */
}

.guestCrossButterFlyIcon {
  position: absolute;
  right: 5%;
  top: 10%;
}

.guestChildMaindiv {
  position: absolute;
  z-index: 10;
  right: 9%;
  top: 50%;
  transform: translateY(-50%);
}

.headerlayout {
  background: linear-gradient(
    90deg,
    var(--default-background-color),
    transparent
  );
  height: auto;
  min-height: 100vh;
  position: relative;
}

.split_letters_two {
  width: 45px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px !important;
  font-weight: 600 !important;
  background-color: #e0e9f2;
  cursor: pointer;
  border: 5px solid #854dff;
  display: inline-block;
}
.avatar_url {
  border-radius: 50%;
  background-color: #e0e9f2;
  cursor: pointer;
}
.no_notification {
  height: 100% !important;
  display: grid !important;
  place-items: center !important;
  font-size: 19px !important;
  margin-top: 20px !important;
}
.notification_part{
  min-height: 100px;
  max-height: 250px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
/* width */
.notification_part::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.notification_part::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.notification_part::-webkit-scrollbar-thumb {
  background: #1c085069 !important;
  border-radius: 5px;
}
.notify_badge span{
  transform: scale(1) translate(22%, -30%) !important;
}
.bottom_nav{
  position: fixed;
  bottom: -1px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  width: 100% !important;
  z-index: 1;
}
@media screen and (max-width: 850px) {
  .guestChildMaindiv {
    right: unset !important;
  }
}
