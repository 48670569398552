.main_title {
  font-size: 32px !important;
  font-weight: 700 !important;
  color: var(--default-text-color) !important;
  padding: 16px 0;
}

.profile_details {
  background-color: #854dff;
  border-radius: 24px;
  padding: 15px 0 15px 15px;
  height: 100%;
}
.split_letters {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 44px !important;
  font-weight: 600 !important;
  background-color: #e0e9f2;
  cursor: pointer;
}
.split_letters_two {
  width: 45px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 25px !important;
  font-weight: 600 !important;
  background-color: #e0e9f2;
  cursor: pointer;
  border: 5px solid #854dff;
}
.avatar_url {
  border-radius: 50%;
  background-color: #e0e9f2;
  cursor: pointer;
}
.profile_name {
  font-size: 32px !important;
  font-weight: 700 !important;
  color: #fff;
}

.label {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #fff;
}

.text {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #fff;
}

.myprogress {
  background-color: #26488b !important;
  border-radius: 24px;
  padding: 10px;
  height: 100%;
  /* cursor: pointer; */
}

.myprogress_title {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #fff !important;
}

.progress_level {
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.select_friend_wrapper {
  background-color: #fff !important;
  border-radius: 24px;
  position: relative;
  padding: 24px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
  height: 100%;
}

.mydiamonds {
  background-color: #ff942a !important;
  border-radius: 24px;
  position: relative;
  padding: 24px;
  height: 100%;
}

.mydiamonds p {
  color: #fff !important;
  font-weight: 600 !important;
}

.cardbutton {
  height: 50px !important;
  min-width: 140px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-align: center !important;
  text-transform: none !important;
  color: var(--btn-text-color) !important;
  background: #fff !important;
  border-radius: 30px !important;
  margin-top: 100px !important;
}
.renewbutton {
  height: 35px !important;
  min-width: 109px !important;
  font-size: 20px !important;
  margin-bottom: 7px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-align: center !important;
  text-transform: none !important;
  color: var(--btn-text-color) !important;
  background: #fff !important;
  border-radius: 30px !important;
  margin-top: 92px !important;
}

.mydiamonds img {
  position: absolute;
  right: 0;
  top: 10px;
}

.subscription {
  background-color: #ffc700 !important;
  border-radius: 24px;
  position: relative;
  padding: 24px;
  height: 100%;
}

.subscription p {
  color: var(--secondary-text-color) !important;
  font-weight: 600 !important;
}

.subscription img {
  position: absolute;
  right: 0;
  top: 50px;
  width: 90px;
}

.subscription_date {
  font-weight: 400 !important;
  font-size: 20px !important;
  /* margin-top: 120px !important; */
}

.subscription_date span {
  font-weight: 700 !important;
  font-size: 20px !important;
}

.completed_game {
  background-color: #ffaddb !important;
  border-radius: 24px;
  position: relative;
  padding: 24px;
  height: 100%;
}

.sub_title {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: var(--secondary-text-color) !important;
}

.completed_game div {
  background-image: url("../../Assets/Images/completed.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.completed_game div p {
  font-size: 128px !important;
  font-weight: 700;
}

.int_sec {
  background-color: #6ad7e0 !important;
  border-radius: 24px;
  position: relative;
  padding: 24px;
  height: 100%;
}

.coding {
  border-radius: 14px;
  padding: 14px;
}

.coding img {
  width: 75px;
}

.coding p {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: var(--secondary-text-color) !important;
}

.myachievments {
  background-color: #ffffff !important;
  border-radius: 24px;
  position: relative;
  padding: 24px;
  height: 100%;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
}

.myachievments span {
  color: var(--secondary-text-color-light);
  font-weight: 400 !important;
  font-size: 18px !important;
}

.single_achiev {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.single_achiev p {
  color: var(--secondary-text-color) !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.single_achiev span {
  color: var(--secondary-text-color) !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.bottom_title {
  color: var(--forgot-password-text-color) !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  text-align: center;
  cursor: pointer;
}

.achievment_cup {
  position: absolute;
  top: 50px;
  right: 40px;
  transform: scale(1.5);
}

.parent_info {
  background-color: var(--secondary-text-color) !important;
  border-radius: 24px;
  position: relative;
  padding: 24px;
  margin-top: 16px;
  margin-bottom: 35px;
}

.parent_info_title {
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  margin-bottom: 20px;
}

.parent_info button {
  height: 40px !important;
  min-width: 140px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-align: center !important;
  text-transform: none !important;
  color: var(--btn-text-color) !important;
  background: #fff !important;
  border-radius: 30px !important;
}

.myachievment_wrapper {
  background-color: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
  padding: 24px;
  border-radius: 24px;
}

.inventory_name {
  color: #4b4b4b !important;
  font-weight: 700 !important;
  font-size: 40px !important;
  margin: 20px 0;
}

.inventory_level {
  background-color: #fff !important;
  width: 282px !important;
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

.inventory_level p {
  color: var(--secondary-text-color) !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.single_points {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: #fff !important;
  /* width: 200px; */
  text-align: center;
}

.single_points img {
  margin: 20px 0 30px 0;
  width: 100px;
  height: 70px;
}

.n_diamond {
  padding: 8px;
  text-align: center;
  color: var(--secondary-text-color) !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.n_diamond svg {
  width: 27px;
}

.single_points button {
  height: 45px !important;
  min-width: 150px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-align: center !important;
  text-transform: none !important;
  color: var(--white);
  background: var(--forgot-password-text-color) !important;
  border-radius: 30px !important;
  margin: 15px 0;
  box-shadow: 0 0 35px #5d689270;
}

.single_points_locked {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 12px 12px 24px 24px;
  background-color: #fff !important;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 16px;
  padding-bottom: 50px;
  position: relative;
  gap: 12px;
}

.single_points_locked p {
  color: var(--secondary-text-color-light) !important;
  font-size: 18px !important;
  text-align: start;
  font-weight: 500 !important;
}

.single_points_locked img {
  width: 60px;
  opacity: 0.7;
}

.single_points_locked svg {
  position: absolute;
  bottom: -20px;
  left: 40%;
}

.inv_star {
  position: absolute;
  right: 0;
  bottom: 0px;
  width: 90px;
}

.inv_ballon {
  position: absolute;
  right: 50px;
  top: 100px;
}

.progress_profile_wrapper {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: #fff !important;
  padding: 34px 24px;
}

.progress_profile p {
  color: var(--secondary-text-color) !important;
  font-size: 40px !important;
  font-weight: 700 !important;
}

.progress_profile span {
  color: var(--secondary-text-color) !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.your_progress {
  background-color: #26488b !important;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.first_box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  height: 100%;
  background-color: #fff;
}

.first_box hr {
  color: rgba(0, 0, 0, 0.3);
}

.progress_title {
  color: var(--secondary-text-color) !important;
  font-size: 24px !important;
  font-weight: 700 !important;
}

.d_flex {
  display: flex;
  align-items: center;
}

.progress_sub_title {
  color: var(--secondary-text-color) !important;
  font-size: 19px !important;
  font-weight: 700 !important;
}

.progress_ballon {
  position: absolute;
  right: 50px;
  top: 40px;
}


@media screen and (max-width: 850px) {
  .welcome_box p{
    font-size: 30px !important;
    }
    .mydiamonds img {
      position: absolute;
      right: 3px;
      top: 10px;
      width: 115px;
  }
  .achievment_cup {
    position: absolute;
    top: 12px;
    right: 4px;
    transform: scale(.8);
  }
  .download_now{
    display: none;
  }
  }