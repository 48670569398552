.slider-container{
    position: relative;
}
.slider-container-friend{
    position: relative;
}
.slick-slider {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.slick-list {
    position: relative;
    display: block !important;
    overflow: hidden;
    padding: 100px !important;
    width: 77%;
    margin: 0 50px;
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
}

.slick-slide div {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slick-slide .image {
    object-fit: cover;
    border-radius: 2px;
    min-width: 150px !important;
    width: 80%;
}

.slick-slide div div span {
    margin-top: 10px;
    width: inherit;
    text-align: center;
    font-size: 0.75rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #111;
}


.slick-next,
.slick-prev {
    z-index: 5;
}

.slick-next {
    right: 15px;
    opacity: 0;
    padding: 16px 7px;
}

.slick-prev {
    left: 15px;
    opacity: 0;
    padding: 16px 0px;
}

.slick-next:before,
.slick-prev:before {
    color: #000;
    font-size: 26px;
}

.slick-current {}

.slider-container .slick-current.slick-active img {
    transform: scale(1.1);
    border: 8px solid var(--forgot-password-text-color);
    border-radius: 50%;
    transform: scale(1.5) !important;
    width: 100px !important;
}

.slider-container-friend .slick-current.slick-active img {
    transform: scale(1.5) !important;
}

.slick-slide.slick-cloned{
    width: 0;
}

.choose_ava .react-multiple-carousel__arrow--left{
    position: absolute;
    left: 0;
    background-color: var(--forgot-password-text-color) !important;
}
.choose_ava .react-multiple-carousel__arrow--right{
    position: absolute;
    right:  0px;
    background-color: var(--forgot-password-text-color) !important;
    z-index: 100 !important;
}
.slider-container-friend li{
    text-align: center !important;
}
.slider-container li{
    text-align: center !important;
}