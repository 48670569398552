.select-friend .react-multiple-carousel__arrow--left{
    position: absolute;
    left: 0;
    background-color: var(--forgot-password-text-color) !important;
}
.select-friend .react-multiple-carousel__arrow--right{
    position: absolute;
    right:  0px;
    background-color: var(--forgot-password-text-color) !important;
    z-index: 100 !important;
}