.my_tabs .MuiButtonBase-root.MuiTab-root{
    color: var(--secondary-text-color-light) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}
.my_tabs .MuiButtonBase-root.MuiTab-root.Mui-selected{
    color: var(--secondary-text-color-light) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}
.my_tabs .MuiTabs-indicator{
    background-color:var(--secondary-text-color-light) !important ;
}
.my_switch_tabs .MuiButtonBase-root.MuiTab-root{
    color: var(--secondary-text-color-light) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}
.my_switch_tabs .MuiButtonBase-root.MuiTab-root.Mui-selected{
    color: var(--forgot-password-text-color) !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
}
.my_switch_tabs .MuiTabs-indicator{
    background-color:var(--forgot-password-text-color) !important ;
}