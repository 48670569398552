.welcome_box{
    background-color: #26488B ;
    border-radius: 20px;
    padding: 24px;
}
.welcome_box p{
    font-size: 40px !important;
    font-weight: 700 !important;
    color: #fff !important; 
    margin-bottom: 12px;
}
.welcome_box span{
    font-size: 20px !important;
    font-weight: 700 !important;
    color: #fff !important; 
}
.welcome_box button{
    min-width: 150px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--forgot-password-text-color);
    background: #fff !important;
    border-radius: 30px !important;
    margin: 25px 0;
    box-shadow: 0 0 35px #5d689270;
    display: block;
    padding: 16px 24px !important;
}
.parent_avatar{
    height: 100%;
    min-height: 250px;
    position: relative;
}
.parent_avatar img{
    position: absolute;
    bottom: -15px;
    width: 93%;
    object-fit: contain;
}
.all_topics{
    background-color: #854DFF;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
}
.all_topics_header{
    color:#fff !important ;
    font-size: 30px !important;
    font-weight: 600 !important;
    margin-bottom: 8px;
}
.all_topics img{
    width: 85%;
}
.single_topics{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.add_student{
    background-color: #fff;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
.add_student svg{
    cursor: pointer;
}
.add_student_header{
    color:var(--default-text-color) !important ;
    font-size: 32px !important;
    font-weight: 700 !important;
}
.my_childrens{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    padding: 24px;
    /* margin-bottom: 24px; */
    height: 100%;
}
.profile_name {
    font-size: 32px !important;
    font-weight: 700 !important;
    color: var(--secondary-text-color) !important;
}
.label {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: var(--secondary-text-color) !important;
}
.text {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: var(--secondary-text-color) !important;
}
/* .before_line::before{
    content: "";
    height: 15px;
    width: 1px;
    background-color: var(--secondary-text-color) !important;
    position: absolute;
    right: 82px;
    top: 8px;
} */
 .student_list_card{
    /* position: relative !important; */
 }
 .student_list_card:hover .remove_student{
    visibility: visible;
 }
.remove_student{
    height: 45px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--forgot-password-text-color) !important;
    background: #fff !important;
    border-radius: 30px !important;
    margin: 15px 0;
    box-shadow: 0 0 15px #5d689270;
    padding: 0 30px !important;
    position: absolute;
    bottom: 12px;
    right: 39px;
    /* visibility: hidden; */
    /* transition: 0.3s; */
}
.view_more{
    height: 45px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--white) !important;
    background: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    margin: 15px 0;
    box-shadow: 0 0 15px #5d689270;
    padding: 0 30px !important;
}
.myprofile_details{
    color: var(--secondary-text-color) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    margin: 5px 0 !important;
}
.authHeader {
    font-size: 40px !important;
    line-height: normal !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: var(--default-text-color) !important;
    display: inline-block;
}
.sub_topics_header{
    font-size: 32px !important;
    font-weight: 700 !important;
    color: var(--default-text-color) !important;
    margin: 20px 0 10px 0 !important;
}
.sub_topics_header span{
    font-size: 24px !important;
    font-weight: 400 !important;
    color: var(--default-text-color) !important;
}
.sub_topics{
    background-color: #854DFF !important;
    padding: 16px !important;
    border-radius: 20px;
}
.sub_topics img{
    width: 80px;
}
.sub_topics span{
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #fff !important;
}
.sub_topics p{
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #fff !important;
    margin-top: 16px;
}
.edit_details{
    min-width: 150px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--forgot-password-text-color) !important;
    background: #fff !important;
    border-radius: 30px !important;
    box-shadow: 0 0 5px #5d689270;
    display: block;
    padding: 12px 28px !important;
    border: 2px solid var(--forgot-password-text-color) !important;
}
.my_head{
    font-size: 32px !important;
    font-weight: 700 !important;
    color: var(--default-text-color) !important;
    margin: 16px 0 !important;
}
.change_plan{
    min-width: 150px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--forgot-password-text-color) !important;
    background: #fff !important;
    border-radius: 30px !important;
    box-shadow: 0 0 5px #5d689270;
    display: block;
    padding: 12px 60px !important;
    border: 2px solid var(--forgot-password-text-color) !important;
}
.renew_plan{
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--white) !important;
    background: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    margin: 15px 0;
    box-shadow: 0 0 15px #5d689270;
    padding: 12px 60px !important;
    border: 2px solid var(--forgot-password-text-color);
}
.subscription_logo{
    position: absolute;
    right: 0;
    top: 55px;
}
.diamond_logo{
    position: absolute;
    right: 0;
    top: 0px;
}
.redeem_diamond{
    background-color: #fff !important;
    border-radius: 20px;
    padding: 24px ;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    height: 100%;
    display: flex;
    align-items: center;
    gap: 100px;
}
.redeem_diamond p{
    font-weight: 700 !important;
    color: var(--secondary-text-color) !important;
}
.redeem_diamond span{
    display: block;
    font-size: 32px !important;
    font-weight: 700 !important;
    color: #4B4B4B !important;
    text-align: center;
}
.download_btn {
    color: var(--forgot-password-text-color) !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    text-align: center;
    cursor: pointer;
}
.TextInput1>div {
    border-radius: 12px !important;
    height: 56px !important;
    background-color: var(--default-input-bg) !important;
    border-color: var(--default-input-bg) !important;
    filter: drop-shadow(0px 4px 4px var(--default-input-shadow)) !important;
}
.TextInput fieldset,
.TextInput>div:hover,
.TextInput1 fieldset,
.TextInput1>div:hover {
    border-color: var(--default-input-bg) !important;
}
.TextInput input::placeholder,
.TextInput1 input::placeholder {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: var(--secondary-text-color);
    opacity: 1 !important;
}
.selectAge {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    color: var(--secondary-text-color);
    opacity: 1 !important;
    margin: 0 !important;
}
.feature_height_two{
    height: 170px !important;
    overflow-y: scroll;
    overflow-x: hidden;
}
.feature_align{
    display: flex !important;
    align-items: start; 
    gap: 4px !important;
  }

  @media screen and (max-width: 850px) {
  .welcome_box p{
    font-size: 28px !important;
    }
  }