.success-popup .MuiPaper-root.MuiDialog-paper{
    width: 700px;
    height: 400px !important;
}
.success-popup .success-img{
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    position: relative;
}
.success-popup .success-img img{
    margin-top: -35px ;
}
.success-popup .success-img p{
    color: var(--secondary-text-color) !important;
    font-size: 24px !important;
    font-weight: 600 !important;
}
.success-popup .success-img svg{
    position: absolute;
    top: 2px;
    right: 8px;
    width: 29px;
    cursor: pointer;
}
.success-popup .success-img .success-bg{
    position: absolute;
    top: 35px;
    left: 0;
    width: 300px;
}
/* *********** end ************ */

/* ****** Instuction Pop ******** */
.instruction-popup .MuiPaper-root.MuiDialog-paper{
    width: 850px !important;
    height: 500px !important;
    background-color: #EFF8FF !important;
    text-align: center;
    max-width: 850px !important;
}
.instruction-popup .sub-instruction{
    height: 82% !important;
    position: relative;
}
.instruction-popup .main-title{
    color: var(--default-text-color) !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    padding: 8px 0;
}
.instruction-popup .instruction-bottom{
    background-color: #fff !important;
    width: 90%;
    margin: auto;
    border-radius: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
    position: relative;
    padding: 0 20px;
}
.card-one{
    position: absolute;
    bottom: 100px;
    right: 50px;
    width: 75px !important;
    z-index: 100;
}
.card-two{
    position: absolute;
    bottom: 12px;
    left: 100px;
    z-index: 100;
}
.card-three{
    position: absolute;
    top: 100px;
    left: 70px;
    z-index: 100;
}
.instruction-popup .title-one{
    color: var(--secondary-text-color) !important;
    font-weight: 700 !important;
    font-size: 24px !important;
}
.instruction-popup .title-two{
    color: var(--secondary-text-color-light) !important;
    font-weight: 500 !important;
    font-size: 24px !important;
}
.instruction-popup .instruction-bottom img{
    /* width: 268px !important; */
}
.instruction-popup .next-btn{
    height: 50px !important;
    min-width: 140px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--white);
    background: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    border: 1px solid  var(--forgot-password-text-color) !important;
}
.instruction-popup .skip-btn{
    height: 50px !important;
    min-width: 140px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--btn-text-color) !important;
    background: #fff !important;
    border-radius: 30px !important;
    border: 1px solid  var(--btn-text-color) !important;
}
.instruction-popup .success-bg{
    position: absolute;
    top: 0px;
    left: 0;
    width: 300px;
}
/* *********** end ************ */

/* ****** MyGoal Pop ******** */
.mygoal-popup .MuiPaper-root.MuiDialog-paper{
    width: 800px !important;
    height: 570px !important;
    background-color: #EFF8FF !important;
    text-align: center;
    max-width: 850px !important;
}
.mygoal-popup .sub-mygoal{
    height: 82% !important;
    position: relative;
}
.mygoal-popup .main-title{
    color: var(--default-text-color) !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mygoal-popup .bottom-contents{
    background-color: #fff !important;
    width: 90%;
    margin: auto;
    border-radius: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
    position: relative;
    padding: 10px 20px;
}
.card-one{
    position: absolute;
    bottom: 100px;
    right: 50px;
    width: 75px !important;
    z-index: 100;
}
.card-two{
    position: absolute;
    bottom: 12px;
    left: 100px;
    z-index: 100;
}
.card-three{
    position: absolute;
    top: 100px;
    left: 70px;
    z-index: 100;
}
.mygoal-popup .title-one{
    color: var(--secondary-text-color) !important;
    font-weight: 500 !important;
    font-size: 24px !important;
}
.mygoal-popup .title-two{
    color: var(--secondary-text-color-light) !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    display: flex;
    align-items: center;
}
.mygoal-popup .bottom-contents img{
    width: 228px !important;
}
.mygoal-popup .next-btn{
    height: 50px !important;
    min-width: 140px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--white);
    background: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    border: 1px solid  var(--forgot-password-text-color) !important;
}
.mygoal-popup .skip-btn{
    height: 50px !important;
    min-width: 140px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--btn-text-color) !important;
    background: #fff !important;
    border-radius: 30px !important;
    border: 1px solid  var(--btn-text-color) !important;
}
.mygoal-popup .success-bg{
    position: absolute;
    top: 0px;
    left: 0;
    width: 300px;
}
/* *********** end ************ */

/* ****** Level complete Pop ******** */
.level-popup .MuiPaper-root.MuiDialog-paper{
    width: 800px !important;
    height: 400px !important;
    background-color: #EFF8FF !important;
    text-align: center;
    max-width: 850px !important;
}
.level-popup .sub-level{
    height: 100% !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.level-popup .main-title{
    color: var(--default-text-color) !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.level-popup .level-bottom{
    background-color: #fff !important;
    width: 90%;
    margin: auto;
    border-radius: 24px;
    height: 89%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    z-index: 1;
    position: relative;
    padding: 10px 20px;
}
.level-popup .title-one{
    color: var(--secondary-text-color) !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    display: flex;
    align-items: center;
}
.level-popup .title-two{
    color: var(--default-text-color) !important;
    font-weight: 700 !important;
    font-size: 32px !important;
}
.level-popup .title-three{
    color: var(--secondary-text-color-light) !important;
    font-weight: 400 !important;
    font-size: 24px !important;
}
.level-popup .title-three span{
    color: var(--secondary-text-color) !important;
    font-weight: 700 !important;
    font-size: 24px !important;
}
.level-popup .bottom-contents img{
    width: 228px !important;
}
.level-popup .next-btn{
    height: 50px !important;
    min-width: 140px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--white);
    background: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    border: 1px solid  var(--forgot-password-text-color) !important;
}
.level-popup .skip-btn{
    height: 50px !important;
    min-width: 140px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--btn-text-color) !important;
    background: #fff !important;
    border-radius: 30px !important;
    border: 1px solid  var(--btn-text-color) !important;
}
.level-popup .success-bg{
    position: absolute;
    top: 0px;
    left: 0;
    width: 300px;
}
/* *********** end ************ */
/* ****** Level complete Pop ******** */
.select-user-popup .MuiPaper-root.MuiDialog-paper{
    width: 500px !important;
    height: auto !important;
    min-height: 450px !important;
    text-align: center;
    max-width: 850px !important;
    padding: 24px;
    position: relative;
}
.select-user-title{
    color: var(--secondary-text-color) !important;
    font-size: 24px !important;
    font-weight: 700 !important;
}
.select-user-content{
    color: var(--secondary-text-color) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
}
.user-card{
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 14px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.user-card div{
    height: 74px;
    width: 50%;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: linear-gradient(70deg, #9b95ba66,#F2EDFF);
}
.user-card p{
    color: var(--secondary-text-color) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.user-card button{
    height: 45px !important;
    min-width: 150px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--white);
    background: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
    box-shadow:  0 0 25px #5d689270;
    border: none;
}
.select-user-popup .close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
}
.select-user-popup .close-icon svg{
    width: 32px;
    cursor: pointer;
}
.select-user-popup .heart-icon{
    position: absolute;
    left: 10px;
    top: 50%;
}