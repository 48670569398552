.paymentlogo {
  height: 40px !important;
  position: absolute;
  left: 10%;
  top: 10px;
}

.guestOrangeRocketIcon {
  position: absolute;
  bottom: 0;
  left: 0;
}

.guestStarIcon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
}

.SubscriptionIndexMainDiv {
  position: relative;
}

.subscriptionCardMain {
  width: 282px !important;
  border-radius: 25px !important;
  height: 100% !important;
  padding-bottom: 30px !important;
  position: relative;
}

.paymentMainHeader {
  font-size: 40px !important;
  font-weight: 700 !important;
  line-height: 44.8px !important;
  text-align: center;
  color: var(--secondary-text-color);
}

.SubscriptionMainContainer {
  /* position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content; */
  padding-top: 82px !important;
}

.headerContainer {
  height: 67px !important;
  padding: 20px !important;
}

.cardHeader {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 26.88px !important;
  text-align: left;
  color: var(--secondary-text-color);
}

.cardContentContainer {
  padding: 20px !important;
}

.cardPrice {
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 38.73px !important;
  text-align: left;
  color: var(--secondary-text-color);
}

.cardPrice > span {
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 21.78px !important;
  text-align: left;
  color: var(--secondary-text-color);
}

.cardSubHeader {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 20.16px !important;
  text-align: left;
  color: var(--secondary-text-color);
}

.paymentMethodCard {
  width: 100% !important;
  padding: 30px !important;
}

.cardSubContent {
  display: flex !important;
  column-gap: 5px !important;
  align-items: center !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 17.92px !important;
  text-align: left;
  color: var(--secondary-text-color-light);
}

.chooseLevelBtn {
  position: absolute !important;
  background-color: var(--forgot-password-text-color) !important;
  box-shadow: 0px 2px 8px 0px #6b75e080 !important;
  height: 56px;
  min-width: 242px !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
  letter-spacing: 1px !important;
  text-align: center;
  text-transform: none !important;
  border-radius: 50px !important;
  bottom: -26px;
  z-index: 30;
  left: 50%;
  transform: translateX(-50%);
}

.bigButterFltIcon {
  position: absolute;
  right: 5%;
  bottom: 5%;
}

.bigButterFltIcon svg {
  width: 75px !important;
  height: 75px !important;
}

.subscriptionsContainer {
  display: flex;
  column-gap: 20px !important;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 50px;
}

.planDetailsRow {
  height: 100vh !important;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.planDetailsLeftContainer {
  padding: 0 !important;
  position: relative;
  display: grid;
  place-items: center;
}

.planDetailsRighttContainer {
  padding: 0 !important;
  background: var(--white) !important;
  box-shadow: 0 0 20px 0 var(--default-input-shadow) !important;
  padding: 3.5% 5% !important;
  position: relative;
  z-index: 2;
}

.ParashuteIcon {
  position: absolute;
  bottom: 2%;
  left: 2%;
}

.ContentContainer {
  position: absolute !important;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);
}

.planDetailsCard {
  width: 100% !important;
}

.paymentListDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.paymentSubText {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0em;
  text-align: left;
  color: var(--login-sub-header-text-color) !important;
}

.cardHeader1 {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: left;
  color: var(--side-nav-background-color) !important;
}

.CouponInput {
  margin-bottom: 0 !important;
}

.CouponInput > div {
  border-radius: 12px;
  background: #fafaff;
  box-shadow: 0px 4px 4px 0px #e3f0ff;
}

.Couponcontainer {
  justify-content: space-between !important;
  padding: 10px 15px;
  background-color: #6ad7e01a;
}

.closeButton {
  min-width: 0px;
  margin-left: 10px;
  padding: 0;
}

.closeButton svg {
  width: 18px !important;
}

.paymentTextWeight {
  font-weight: 500 !important;
}

.textDetailsContainer {
  position: absolute;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
}

.applyButton {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  letter-spacing: 0em;
  text-align: right;
  color: var(--forgot-password-text-color) !important;
  cursor: pointer;
}

.remove {
  margin-left: 10px;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.makePaymentBtnContainer {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.makePaymentBtn {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
  letter-spacing: 1px !important;
  text-align: center;
  background-color: var(--default-btn-color) !important;
  border-radius: 40px !important;
  text-transform: none !important;
}

.BaloonhandIcon {
  position: absolute;
  left: 0;
  bottom: 0;
}

.MethodsListContainer {
  display: flex;
  column-gap: 15px;
  align-items: center;
}

.MethodTxt {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0em;
  text-align: left;
  color: var(--side-nav-background-color) !important;
}

.methodCard {
  display: flex;
  flex-direction: column;
  background-color: #ffc7001a !important;
  padding: 15px;
  height: 74px;
  justify-content: center;
  row-gap: 5px;
  border-radius: 12px !important;
}

.formLable {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0em;
  text-align: left;
  color: var(--side-nav-background-color) !important;
}

.cardMethodBox {
  width: 27% !important;
}

.TextInput {
  margin-bottom: 0 !important;
}

.TextInputCvv {
  display: flex !important;
  margin-bottom: 0 !important;
}

.TextInputCvv > div {
  width: 92% !important;
}

.questionsvg {
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.cardNumberInput {
  width: 100%;
  border-radius: 12px !important;
  height: 56px !important;
  background-color: var(--default-input-bg) !important;
  border: none !important;
  box-shadow: 0px 4px 4px 0px #e3f0ff !important;
  padding: 5px 10px;
}

.cardImage {
  position: absolute;
  top: 50px !important;
  right: 10px;
}

.ErrorText {
  color: red !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  letter-spacing: 0em;
  display: flex;
}

.PAymentContntContainer {
  transform: translate(-50%, -50%);
}

.forgetPasswordmodalclose {
  position: absolute;
  top: 0;
  right: 0;
}

.MainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  row-gap: 40px;
}

.ReceiptMainHeading {
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 45px !important;
  letter-spacing: 0em;
  text-align: left;
  color: var(--side-nav-background-color) !important;
}

.cardHeader2 {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  letter-spacing: 0em;
  text-align: left;
  color: var(--side-nav-background-color) !important;
}

.cardSubHeader2 {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 27px !important;
  letter-spacing: 0em;
  text-align: left;
  color: var(--side-nav-background-color) !important;
}

.HeaderContainer {
  display: flex;
  justify-content: space-between;
  padding: 25px 30px;
  background-color: #ffc7001a !important;
}

.ReceiptMainContainer {
  padding: 30px 30px;
}

.ReceiptMainContainer1 {
  padding: 20px 30px;
}

.ReceiptSubHeaderContainer {
  padding: 15px 30px;
  background-color: #ba9bff1a;
}

.DownloadReceipt {
  width: 242px;
  height: 54px;
  border-radius: 126px !important;
  border-width: 3px !important;
  box-shadow: 0px 2px 8px 0px rgba(107, 117, 224, 0.5);
  font-size: 19px !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.ReceiptbtnContainer {
  display: flex;
  column-gap: 15px;
}

.paymentSubHeaderNote {
  font-size: 15px !important;
  line-height: 18px !important;
}

.feature_height{
  height: 150px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}
.feature_align{
  display: flex !important;
  align-items: start; 
  gap: 4px !important;
}
.cardContentContainer:hover .feature_height::-webkit-scrollbar {
  width: 5px !important;
}

/* width */
.feature_height::-webkit-scrollbar {
  width: 0px;
  transition: 0.2s;
}

/* Track */
.feature_height::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.feature_height::-webkit-scrollbar-thumb {
  background: #1c085069 !important;
  border-radius: 5px;
}

.feature_height_two{
  height: 200px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}
/* width */
.feature_height_two::-webkit-scrollbar {
  width: 0px;
  transition: 0.2s;
}

/* Track */
.feature_height_two::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.feature_height_two::-webkit-scrollbar-thumb {
  background: #1c085069 !important;
  border-radius: 5px;
}
.cardContentContainer:hover .feature_height_two::-webkit-scrollbar {
  width: 5px !important;
}

@media screen and (max-width: 768px) {
  .SubscriptionMainContainer {
    padding-top: 82px !important;
    /* display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90%;
        margin: auto !important; */
    /* left: unset; */
    /* top: 50%;
        left: 50%;
        transform: translateY(-50%); */
  }

  .subscriptionsContainer {
    width: 100%;
    overflow-x: scroll;
    padding: 0 0 30px !important;
  }

  .paymentMainHeader {
    width: 80% !important;
  }
}
