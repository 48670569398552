.myprofile-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 90px 0px 90px;
    background-color: #fff !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    /* position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; */
}
.nav-imgs{
    cursor: pointer;
}
.navactive button{
    font-size: 18px !important;
    font-weight: 700 !important; 
    color: var(--forgot-password-text-color) !important;
    margin-top: 6px;
    position: relative;
    text-transform: capitalize !important;
}
.navactive button::after{
    content: "";
    position: absolute;
    bottom: 0px;
    height: 4px !important;
    width: 100% !important;
    background-color: var(--forgot-password-text-color) !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.navinactive button{
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-text-color-light) !important;
    margin-top: 6px;
    text-transform: capitalize !important;
}
.navactive path{
    fill: var(--forgot-password-text-color) !important;
}
.navinactive path{
    fill: var(--secondary-text-color-light) !important;
}
.dashboard-home{
    background: linear-gradient(90deg, var(--default-background-color), transparent);
    height: auto;
    position: relative;
    margin-top: 26px;
}
.algo-games{
    position: relative;
    background: linear-gradient(90deg, #FEF6F1, #FEF6F1);
    padding: 30px 0;
}
.game-a{
    position: absolute;
    top: 30px;
    left: 500px;
}
.game-ballon{
    position: absolute;
    top:30px ;
    right:30px ;
}
.game-cloud{
    position: absolute;
    top: 10px;
    left:770px ;
    width: 200px;
}
.game-rocket{
    position: absolute;
    top:300px ;
    left:0 ;
}
.game-butterfly{
    position: absolute;
    top:35px ;
    left: 20px;
}
.bottom-content{
    display: flex;
    align-items: center;
    margin-top: 100px;
}
.bottom-content img{
    width: 85px;
}
.bottom-content p{
    font-size: 22px !important;
    color: var(--secondary-text-color) !important;
    font-weight: 500 !important;
}
.dashboard-mycode{
    background: linear-gradient(90deg, var(--default-background-color), transparent);
    height: auto;
    position: relative;
    min-height: 100vh;
    padding: 16px 0;
}
.myprofile-container .MuiButtonBase-root.MuiTab-root.Mui-selected{
    color: var(--forgot-password-text-color) !important;
}
.menu{
    font-size: 20px !important;
    min-height: 0px !important;
    text-transform: capitalize !important;
}
.menu svg{
    margin-right: 6px;
}
.left-box{
    position: relative;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #fff !important;
    border-radius: 24px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.left-box p{
    font-size: 30px !important;
    color: var(--secondary-text-color) !important;
    font-weight: 600 !important;
    margin-left: 28px;
}
.left-box img{
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0.8;
}
.left-box button{
    height: 50px !important;
    min-width: 140px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--white);
    background: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
}
.center-box{
    position: relative;
    min-height: 250px;
    background-color: var(--dashboard-bg-color) !important;
    border-radius: 24px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    height: 100%;
    padding: 24px !important;
}
.center-box p{
    color: #fff !important;
    font-weight: 600 !important;
}
.center-box button{
    height: 50px !important;
    min-width: 140px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--btn-text-color) !important;
    background: #fff !important;
    border-radius: 30px !important;
}
.welcome-img{
    position: absolute;
    bottom: 0;
    right: 0;
}
.img-one{
    position: absolute;
    bottom: 0;
    left: 30px;
}
.img-two{
    position: absolute;
    top: 27%;
    right: 30px;
} 
.right-box-one{
    position: relative;
    min-height: 250px;
    background-color: #26488B !important;
    border-radius: 24px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 24px;
    /* cursor: pointer; */
}
.right-box-one p{
    color: #fff !important;
    font-weight: 600 !important;
}
.right-box-two{
    background-color: #FF942A !important;
    border-radius: 24px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 24px;
    position: relative;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.right-box-two p{
    color: #fff !important;
    font-weight: 600 !important;
}
.right-box-two button{
    height: 50px !important;
    min-width: 140px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--btn-text-color) !important;
    background: #fff !important;
    border-radius: 30px !important;
}
.btn-one{
    height: 50px !important;
    min-width: 140px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-align: center !important;
    text-transform: none !important;
    color: var(--white) !important;
    background: var(--forgot-password-text-color) !important;
    border-radius: 30px !important;
}
.right-box-two img{
    position: absolute;
    right: 0;
    top: 10px;
}
.all-topics-wrapper{
    padding: 14px 0;
    background-color: #854DFF !important;
    margin-top: 16px;
    width: 100%;
}
.all-topics{
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* flex-wrap: wrap; */
    overflow-x: scroll;
}
.all-topics::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
.all-topics::-webkit-scrollbar-track {
    background:  #854DFF; 
}
.all-topics::-webkit-scrollbar-thumb {
    background: #f1f1f1 !important;
    border-radius: 5px;
}
.mycode-title{
    color: var(--default-text-color) !important;
    font-size: 32px !important;
    font-weight: 700 !important; 
}
.achievement-box{
    height: 95%;
    background-color: #fff ;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    /* width: 350px; */
    border-radius: 24px;
    padding: 16px;
    position: relative;
}
.achievement-title{
    color: var(--secondary-text-color) !important;
    font-weight: 600 !important;
    font-size: 24px !important;
}
.achievement-sub-title{
    color: var(--secondary-text-color) !important;
    font-weight: 400 !important;
    font-size: 20px !important;
}
.achievement-percent{
    color:var(--secondary-text-color) !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    margin-top: 12px !important;
}
.achievement-box img{
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
}
.achievments-slider .rightarrowfriend{
    position: absolute;
    right: -16px;
    top: 41%;
}
.achievments-slider .leftarrowfriend{
    position: absolute;
    left: -18px;
    top: 41%;
}
.slider-container-friend{
    position: relative;
}
.achievments-slider{
    position: relative;
    margin: 24px 0 !important;
    z-index: 1;
}
.achievments-slider .react-multi-carousel-item{
    /* width: 428px !important; */
}
.achievments-slider .react-multi-carousel-list{
    /* width: 100%; */
    margin: auto;
}
.level-cards .react-multi-carousel-list{
    padding: 39px 0 !important;
}
/* .level-cards li{
    width: 300px !important;
} */
.level-cards .react-multiple-carousel__arrow--left{
    position: absolute;
    left: 0;
    background-color: var(--forgot-password-text-color) !important;
}
.level-cards .react-multiple-carousel__arrow--right{
    position: absolute;
    right:  0px;
    background-color: var(--forgot-password-text-color) !important;
    z-index: 100 !important;
}
.achievments-slider .react-multiple-carousel__arrow--left{
    position: absolute;
    left: 0px;
    background-color: var(--forgot-password-text-color) !important;
    z-index: 1000;
}
.achievments-slider .react-multiple-carousel__arrow--right{
    position: absolute;
    right:  0px;
    background-color: var(--forgot-password-text-color) !important;
    z-index: 100 !important;
}
.achievments-slider .react-multiple-carousel__arrow--left{
    position: absolute;
    left: 0px;
    background-color: var(--forgot-password-text-color) !important;
    z-index: 1000;
}
.achievments-slider .react-multiple-carousel__arrow--right{
    position: absolute;
    right:  0px;
    background-color: var(--forgot-password-text-color) !important;
    z-index: 100 !important;
}
.popper-wrapper{
    width: 380px;
}
.popper-row-one{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}
.popper-row-one p{
    color: #131313 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
}
.popper-row-one span{
    color: var(--forgot-password-text-color) !important;
    font-weight:700 !important;
    font-size: 16px !important;
    cursor: pointer;
}
.single-notification{
    display: flex;
    align-items: start;
    gap: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 16px;
    cursor: pointer;
}
.single-notification p{
    font-size: 15px !important;
    font-weight: 500;
}
.single-notification span{
    font-size: 13px;
    font-weight: 500;
}
.read-all{
    color: var(--forgot-password-text-color) !important;
    font-weight:700 !important;
    font-size: 18px !important;
    text-align: center;
    padding: 16px 0;
    cursor: pointer;
}
.recommended-box{
    height: 95%;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    width: 324px;
    border-radius: 24px;
    padding: 16px;
    position: relative;
}



@media screen and (max-width: 850px) {
    .myprofile-container{
        padding: 6px 20px 0px 20px;
    }
    .rocket-icon{
        display: none;
    }
    .welcome-img{
        position: absolute;
        bottom: 17px;
        right: 22px;
        width: 77px;
        z-index: 1;
    }
    .center-box button{
        margin-top: 55px;
        z-index: 1;
    }
    .game-cloud{
       left: unset;
       right: 0px;
    }
    .game-a{
        display: none;
    }
  }