.firstContainer {
    display: grid;
    grid-template-columns: 45% 55%;
    column-gap: 20px;
    width: calc(100% - 20px);
}

.subcontainer1 {
    background: var(--school-side-nav-background-color);
    border-radius: 8px;
}

.root {
    margin: "10px 10px";
    display: "flex";
    flex-direction: "column";
}

.mainheading {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 36px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.SubMainHeading {
    font-size: 23px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--default-text-color) !important;
}

.profileLable {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.profileValueText {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.subcontainer2 {
    padding: 20px;
    position: relative;
    min-height: 340px;
}

.SubMainHeading2 {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--side-nav-background-color);
}

.TableBodyText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    text-transform: capitalize;
    color: var(--side-nav-background-color);
}

.HeadingTextLable {
    font-weight: 500 !important;
}

.showPassword {
    position: absolute;
    bottom: 17px;
    right: 0;
}

.MenuItemText {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.descriptioncontainer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.ExtrafontWeight {
    font-weight: 600 !important;
}

.subscriptionFooter {
    position: absolute;
    display: flex;
    align-items: center;
    width: calc(100% - 40px);
    justify-content: space-between;
    bottom: 20px;
}

.subscriptionBtnSection {
    display: flex;
    column-gap: 20px;
}

.ChangePasswordBtnSection {
    display: flex;
    column-gap: 20px;
    justify-content: flex-end;
}

.subscriptionBtnSection button,
.ChangePasswordBtnSection button {
    border-radius: 75px;
    width: 145px;
    height: 40px;
    border: 2px solid var(--forgot-password-text-color);
    text-transform: capitalize !important;
}


.updatePassBtnfromProfile {
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: left;
    cursor: pointer !important;
    color: var(--forgot-password-text-color);
}


.TableContainer {
    overflow-x: scroll !important;
}

.TableClass th,
.TableClass td {
    height: 48px;
    padding: 0 16px !important;
}

.TableHeaderRow {
    background-color: var(--table-header-background-color);
    height: 48px;
}

.TableHeaderRow>th {
    background-color: transparent !important;
}

.firstCell {
    padding-left: 15px !important;
}

.TableHeaderText {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    text-align: left;
    color: var(--side-nav-background-color);
}

.someNrmlText {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0em;
    text-align: left;
    color: var(--login-sub-header-text-color);
}

.StatusChip {
    padding: 10px 8px !important;
}

.headeingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchBar {
    width: 300px !important;
    display: flex !important;
}

.searchBar>div {
    border-radius: 75px !important;
}

.AccordianContainer {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.AccordianTab {
    min-height: 70px !important;
    padding: 20px 10px;
}

@media screen and (max-width: 767px) {
    .firstContainer {
        grid-template-columns: 100%;
        row-gap: 15px;
        width: 100%;
    }

    .subscriptionFooter {
        flex-direction: column;
        align-items: flex-end;
        row-gap: 15px;
    }

    .subcontainer2 {
        height: 420px;
    }

    .headeingContainer {
        display: grid;
        row-gap: 5px;
    }

    .ChangePasswordBtnSection {
        display: flex;
        justify-content: space-between;
    }

    .ChangePasswordBtnSection button {
        width: 49%;
    }
}